export enum ProductType {
    engineeredHardwood = 'engineered_hardwood',
    solidHardwood = 'solid_hardwood',
    laminate = 'laminate',
    lvtVinyl = 'vinyl',
    carpet = 'carpet',
    rug = 'rug',
    ceramic = 'ceramic',
    concrete = 'concrete',
    epoxy = 'epoxy',
    epoxyBase = 'epoxy_base',
    epoxyChips = 'epoxy_chips',
    epoxyCombined = 'epoxy_combined',
    floor = 'floor', // deprecated value for backward compatibility
    model3d = 'model3D',
    wall = 'wall',
    porcelain = 'porcelain',
    lvp = 'lvp',
    naturalStone = 'natural_stone',
    spc = 'spc',
    hybridFloor = 'hybrid_floor',
    bambooFlooring = 'bamboo_flooring',
    terrazzo = 'terrazzo',
    polishedConcrete = 'polished_concrete',
    timber = 'timber',
    tiles = 'tiles',
    wpc = 'wpc',
    wallPanel = 'wall_panel',
    paint = 'paint',
    grout = 'grout',
}

export const disabledRotationProductTypes: ProductType[] = [ProductType.epoxy, ProductType.paint];

export const disabledMovePatternProductTypes: ProductType[] = [
    ProductType.paint,
    ProductType.rug,
    ProductType.grout,
];
