<label class="toggle-switch" [class.disabled]="disabled">
    <input
        type="checkbox"
        [checked]="isChecked()"
        [disabled]="disabled"
        (change)="onToggle($event)"
        (focus)="focusChanged(true)"
        (blur)="focusChanged(false)" />
    <span class="toggle-slider"></span>
</label>
