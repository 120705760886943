// Method open() needs to return FlooriDialogRef so cannot remove '!'
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { inject, Injectable, Injector, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { ComponentType, ConnectedPosition, Overlay, PositionStrategy } from '@angular/cdk/overlay';
import { Dialog, DialogConfig } from '@angular/cdk/dialog';
import { Subject } from 'rxjs';
import {
    FlooriDialogProvider,
    FLOORI_DIALOG_DATA,
    FlooriDialogConfig,
    FlooriDialogRef,
} from '../common';
import { FlooriDialogContainerComponent } from '../components/floori-dialog-container/floori-dialog-container.component';
import { flooriHelperClasses } from '../../../constants';
import { DialogType } from '../../../models/dialog-type';

let uniqueId = 0;

@Injectable()
export class FlooriDialogService implements FlooriDialogProvider, OnDestroy {
    private readonly dialogDataToken = FLOORI_DIALOG_DATA;
    private readonly idPrefix = 'fl-dialog';
    private readonly dialog = inject(Dialog);
    private readonly openDialogsAtThisLevel: FlooriDialogRef<any>[] = [];
    private readonly afterAllClosedAtThisLevel = new Subject<void>();
    private readonly afterOpenedAtThisLevel = new Subject<FlooriDialogRef<unknown>>();
    private readonly closeAfterDuration = 2000;

    get afterOpened(): Subject<FlooriDialogRef<unknown>> {
        return this.parentDialog ? this.parentDialog.afterOpened : this.afterOpenedAtThisLevel;
    }

    get openDialogs(): FlooriDialogRef<unknown>[] {
        return this.parentDialog ? this.parentDialog.openDialogs : this.openDialogsAtThisLevel;
    }

    constructor(
        private readonly overlay: Overlay,
        private readonly injector: Injector,
        @Optional() @SkipSelf() private readonly parentDialog: FlooriDialogService,
    ) {}

    ngOnDestroy(): void {
        this.closeDialogs(this.openDialogsAtThisLevel);
        this.afterAllClosedAtThisLevel.complete();
        this.afterOpenedAtThisLevel.complete();
    }

    open<T, D = unknown, R = unknown>(
        componentRef: ComponentType<T>,
        config?: FlooriDialogConfig<D>,
        closeAfter: boolean | number = false,
        dialogType?: DialogType,
    ): FlooriDialogRef<T, R> {
        let dialogRef: FlooriDialogRef<T, R>;
        config = { ...new FlooriDialogConfig(), ...config };
        config.id = config.id || `${this.idPrefix}-${uniqueId++}`;

        if (dialogType === DialogType.snackbar) {
            config.scrollStrategy = this.overlay.scrollStrategies.reposition();
        } else {
            config.scrollStrategy = this.overlay.scrollStrategies.block();
        }

        this.overlay.position();
        const cdkRef = this.dialog.open<R, D, T>(componentRef, {
            ...config,
            panelClass: config?.panelClass || flooriHelperClasses['fullOverlay'],
            positionStrategy: this.getPositionStrategy<D>(config),
            disableClose: config?.disableClose || true,
            closeOnDestroy: config?.closeOnDestroy || false,
            container: {
                providers: () => [
                    { provide: FlooriDialogConfig, useValue: config },
                    {
                        provide: DialogConfig,
                        useValue: config,
                    },
                ],
                type: FlooriDialogContainerComponent,
            },
            providers: (ref, cdkConfig, dialogContainer) => {
                dialogRef = new FlooriDialogRef(
                    ref,
                    config as FlooriDialogConfig,
                    dialogContainer as FlooriDialogContainerComponent,
                );
                if (config?.position) {
                    dialogRef.updatePosition(config?.position);
                }
                const customProviders = config?.providers || [];
                return [
                    ...(customProviders as any),
                    { provide: FlooriDialogContainerComponent, useValue: dialogContainer },
                    { provide: this.dialogDataToken, useValue: cdkConfig.data },
                    { provide: FlooriDialogRef, useValue: dialogRef },
                ];
            },
        });
        dialogRef!.componentInstance = cdkRef.componentInstance!;
        this.openDialogs.push(dialogRef! as FlooriDialogRef<unknown>);
        this.afterOpened.next(dialogRef! as FlooriDialogRef<unknown>);

        const duration =
            typeof closeAfter === 'boolean' && closeAfter ? this.closeAfterDuration : closeAfter;

        if (duration) {
            setTimeout(() => dialogRef.close(), duration);
        }

        return dialogRef!;
    }

    closeAll(): void {
        this.closeDialogs(this.openDialogs);
    }

    getPositionStrategy<D>(config: FlooriDialogConfig<D>): PositionStrategy {
        const defaultPosition = {
            originX: 'start',
            originY: 'top',
            overlayY: 'top',
            overlayX: 'start',
        } as ConnectedPosition;
        const position = this.overlay.position();
        return config?.positionRef
            ? position
                  .flexibleConnectedTo(config.positionRef)
                  .withPositions([
                      config.connectedPosition ? config.connectedPosition : defaultPosition,
                  ])
            : position.global().centerHorizontally().centerHorizontally();
    }

    getDialogById(id: string): FlooriDialogRef<unknown> | undefined {
        return this.openDialogs.find(dialog => dialog.id === id);
    }

    private closeDialogs(dialogs: FlooriDialogRef<unknown>[]): void {
        let i = dialogs.length;

        while (i--) {
            dialogs[i].close();
        }
    }
}
