import {
    ChangeDetectionStrategy,
    Component,
    input,
    output,
    HostListener,
    ElementRef,
    computed,
    signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FlooriControl } from '../../utils';
import { FlooriIconComponent } from '../floori-icon/floori-icon.component';
import { SelectOption } from './select-option';
import { DropdownPosition } from './dropdown-position.enum';

@Component({
    selector: 'floori-select',
    standalone: true,
    imports: [CommonModule, FormsModule, FlooriIconComponent],
    templateUrl: './floori-select.component.html',
    styleUrl: './floori-select.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: FlooriSelectComponent,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlooriSelectComponent extends FlooriControl implements ControlValueAccessor {
    private readonly dopdownHeight = 200;
    readonly options = input.required<SelectOption[]>();
    readonly placeholder = input<string>();

    readonly selectionChange = output<unknown>();

    isOpened = signal(false);
    private _value: unknown = '';

    dropdownPosition = computed(() => {
        if (!this.isOpened()) return '';

        const inputElement = this.elementRef.nativeElement;
        const inputRect = inputElement.getBoundingClientRect();
        const spaceBelow = window.innerHeight - inputRect.bottom;
        const spaceAbove = inputRect.top;

        return spaceBelow < this.dopdownHeight && spaceAbove > spaceBelow
            ? DropdownPosition.above
            : DropdownPosition.below;
    });

    get value(): unknown {
        return this._value;
    }

    set value(val: unknown) {
        if (val !== this._value) {
            this._value = val;
            this.onChange(this._value);
            this.onTouch(this._value);
            this.selectionChange.emit(this._value);
        }
    }

    get selectedLabel(): string {
        return this.options().find(opt => opt.value === this._value)?.label || '';
    }

    constructor(private elementRef: ElementRef) {
        super();
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: MouseEvent) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.isOpened.set(false);
            this.focusChanged(false);
        }
    }

    toggleDropdown(): void {
        if (!this.disabled) {
            this.isOpened.update(isOpen => !isOpen);
            this.focusChanged(this.isOpened());
        }
    }

    selectOption(option: SelectOption): void {
        this.value = option.value;
        this.isOpened.set(false);
    }

    writeValue(value: string): void {
        if (this.onChangeInit) {
            this.value = value;
        }
    }
}
