import { Component, input, output } from '@angular/core';
import { FlooriCardComponent } from '../floori-card/floori-card.component';
import { FlooriButtonComponent } from '../floori-button';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'floori-modal-template',
    standalone: true,
    imports: [FlooriCardComponent, FlooriButtonComponent, CommonModule],
    templateUrl: './floori-modal-template.component.html',
    styleUrl: './floori-modal-template.component.scss',
})
export class FlooriModalTemplateComponent {
    readonly header = input.required<string>();
    readonly primaryButtonName = input<string>();
    readonly secondaryButtonName = input<string>();

    readonly primaryClicked = output();
    readonly secondaryClicked = output();

    onPrimaryClicked(): void {
        this.primaryClicked.emit();
    }

    onSecondaryClicked(): void {
        this.secondaryClicked.emit();
    }
}
