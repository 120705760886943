import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    inject,
    ViewEncapsulation,
} from '@angular/core';
import { ErrorInfoComponent } from '@floori-web/ui/error-info';
import { ErrorDialogData } from '@floori-web/models';
import {
    FlooriButtonComponent,
    DialogHeaderComponent,
    FLOORI_DIALOG_DATA,
    FlooriDialogModule,
    TranslationsModule,
} from '@floori-web-components';

@Component({
    selector: 'floori-error-dialog',
    imports: [
        DialogHeaderComponent,
        FlooriDialogModule,
        ErrorInfoComponent,
        TranslationsModule,
        FlooriButtonComponent,
    ],
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ErrorDialogComponent {
    private readonly data = inject<ErrorDialogData>(FLOORI_DIALOG_DATA);

    get message(): string {
        return this.data?.message || '';
    }

    @HostBinding('class.floori-error-dialog') hostClass = true;
}
