import { inject, Injectable, OnDestroy } from '@angular/core';
import { Event, EventType, Navigation, Router, RouterStateSnapshot } from '@angular/router';
import { NavigationEventsProvider, RouterNavigationEvent } from '@floori-web/models';
import { Subject, Subscription, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { routerActionsMap } from './router-actions-map';

@Injectable()
export class NavigationEventsService implements NavigationEventsProvider, OnDestroy {
    private readonly router = inject(Router);
    private readonly store = inject(Store);
    private readonly actionsMap = routerActionsMap;
    private subscription = Subscription.EMPTY;
    private inited = false;
    readonly lastRoute$ = new Subject<RouterNavigationEvent>();

    get navigation(): Navigation | null {
        return this.router.getCurrentNavigation();
    }

    get snapshot(): RouterStateSnapshot {
        return this.router.routerState.snapshot;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.inited = false;
    }

    init(): void {
        if (this.inited) {
            return;
        }

        this.subscription = this.router.events
            .pipe(
                tap((event: Event) => {
                    const type = (event as { type: EventType })?.type;
                    const action = this.actionsMap.get(type);
                    if (action) {
                        const nav = this.navigation;
                        const navData = this.cloneNavigationData(nav);

                        const routeEvent = new RouterNavigationEvent(
                            event,
                            type,
                            this.router.routerState.snapshot.url,
                            this.snapshot.root.queryParamMap,
                            {
                                extras: navData?.extras,
                                previousNavigation: navData?.previousNavigation,
                                id: navData?.id,
                            },
                        );
                        this.store.dispatch(action(routeEvent));
                        this.lastRoute$.next(routeEvent);
                    }
                }),
            )
            .subscribe();
        this.inited = true;
    }

    private cloneNavigationData(nav: Navigation | null): Navigation | null {
        if (!nav) return null;
        return {
            id: nav.id,
            extras: { ...nav.extras },
            previousNavigation: nav.previousNavigation
                ? {
                      id: nav.previousNavigation.id,
                      extras: { ...nav.previousNavigation.extras },
                      finalUrl: nav.previousNavigation.finalUrl,
                      initialUrl: nav.previousNavigation.initialUrl,
                      extractedUrl: nav.previousNavigation.extractedUrl,
                      trigger: nav.previousNavigation.trigger,
                      previousNavigation: null,
                  }
                : null,
            extractedUrl: nav.extractedUrl,
            trigger: nav.trigger,
            finalUrl: nav.finalUrl,
            initialUrl: nav.initialUrl,
        };
    }
}
