import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Breadcrumb } from './breadcrumb';

@Component({
    selector: 'floori-breadcrumbs',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './floori-breadcrumbs.component.html',
    styleUrl: './floori-breadcrumbs.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlooriBreadcrumbsComponent {
    readonly items = input.required<Breadcrumb[]>();
}
