import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FlooriButtonComponent,
    FlooriDialogModule,
    TranslationsModule,
} from '@floori-web-components';

@Component({
    selector: 'floori-cookies-dialog',
    imports: [CommonModule, TranslationsModule, FlooriDialogModule, FlooriButtonComponent],
    templateUrl: './cookies-dialog.component.html',
    styleUrls: ['./cookies-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesDialogComponent {
    private readonly cdr = inject(ChangeDetectorRef);
    loading = true;

    @HostBinding('class.floori-cookies-dialog') hostClass = true;

    constructor() {
        setTimeout(() => {
            this.loading = false;
            this.cdr.markForCheck();
        }, 200);
    }
}
