export enum Languages {
    ara = 'ara',
    au = 'au',
    bg = 'bg',
    bl = 'bl',
    ch = 'ch',
    cs = 'cs',
    da = 'da',
    de = 'de',
    en = 'en',
    es = 'es',
    fi = 'fi',
    fr = 'fr',
    gr = 'gr',
    hr = 'hr',
    hu = 'hu',
    id = 'id',
    il = 'il',
    it = 'it',
    ja = 'ja',
    kr = 'kr',
    lt = 'lt',
    lv = 'lv',
    nl = 'nl',
    no = 'no',
    pl = 'pl',
    ptbr = 'ptbr',
    pt = 'pt',
    ro = 'ro',
    ru = 'ru',
    sa = 'sa',
    se = 'se',
    sk = 'sk',
    sl = 'sl',
    sr = 'sr',
    tr = 'tr',
    th = 'th',
    uk = 'uk',
    us = 'us',
    vie = 'vie',
}

export interface Language {
    language: string;
    code: Languages;
    flag?: string;
}
