import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { flooriConstants } from '@floori-web-components';

@Component({
    selector: 'floori-splash',
    templateUrl: './splash.component.html',
    styleUrls: ['./splash.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SplashComponent {
    readonly flooriLogo = flooriConstants.flooriLogoVertical;
    @Input() logoUrl = '';

    @HostBinding('class.floori-splash') hostClass = true;
}
