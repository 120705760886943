<div class="circle-wrapper">
    <div class="outline-outer">
        <div class="outline-inner">
            <floori-icon [name]="iconName()" class="type-icon"></floori-icon>
        </div>
    </div>
</div>
<div class="label-wrapper">
    <span>{{ label | flTranslate }}</span>
</div>
<button
    floori-icon-button
    class="small no-border"
    [floori-dialog-close]="true"
    *ngIf="showCloseButton">
    <floori-icon name="fl_close"></floori-icon>
</button>
