<nav class="floori-breadcrumbs-nav">
    <ol>
        @for (item of items(); track item.label; let last = $last) {
        <li class="floori-breadcrumb-item">
            @if (last) {
            <span class="active">{{ item.label }}</span>
            } @else {
            <a [routerLink]="item.link">{{ item.label }}</a>
            <div class="separator">/</div>
            }
        </li>
        }
    </ol>
</nav>
