import { enableProdMode, importProvidersFrom } from '@angular/core';
import { COMPANY_CONFIG_RAW, COMPANY_VENDOR_ID, FLOORI_CONTEXT_ID } from '@floori-web/constants';
import { CompanyConfig, CompanyConfigDto } from '@floori/models';
import { bootstrapApplication, HammerModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FlooriWebShellModule } from '@floori-web/shell/feature';
import { FlooriEnv } from '@floori-web/models';
import { v4 as uuidv4 } from 'uuid';
import {
    FLOORI_ENV,
    FLOORI_ICON_FAMILY,
    FlooriIcons,
    TranslationsLoaderService,
} from '@floori-web-components';
import { AppComponent } from './app/app.component';
import { configWrapper, SetupService } from './app/services';
import { environment } from './environments/environment';
import 'hammerjs'; // Don`t remove. Needed for gestures
import { providePrimeNG } from 'primeng/config';
import Lara from '@primeng/themes/aura';

// Attach the polyfill as a Global function

if (!globalThis.structuredClone) {
    globalThis.structuredClone = (obj: any): any => JSON.parse(JSON.stringify(obj));
}

if (environment.production) {
    enableProdMode();
}

const env: FlooriEnv = { ...environment } as const;

const setup = new SetupService();
setup.bootstrap().then((companyConfig: CompanyConfigDto) => {
    bootstrapApplication(AppComponent, {
        providers: [
            importProvidersFrom(
                FlooriWebShellModule,
                HammerModule,
                // ServiceWorkerModule.register('ngsw-worker.js', {
                //     enabled: environment.production,
                //     // Register the ServiceWorker as soon as the application is stable
                //     // or after 30 seconds (whichever comes first).
                //     registrationStrategy: 'registerWhenStable:30000',
                // }),
            ),
            {
                provide: SetupService,
                useValue: setup,
            },
            {
                provide: FLOORI_ENV,
                useFactory: (): FlooriEnv => ({
                    production: env.production,
                    envName: env.envName,
                    apiUrl: env.apiUrl,
                    cdnUrl: env.cdnUrl,
                    flooriWebsite: env.flooriWebsite,
                    bookDemoUrl: env.bookDemoUrl,
                    supportEmail: env.supportEmail,
                    version: env.version,
                    sentryKey: env.sentryKey,
                    gtmUrl: env.gtmUrl,
                    gtmKey: env.gtmKey,
                    interactionKey: env.interactionKey,
                    showDebugData: !!env.showDebugData,
                    showDevTools: !!env.showDevTools,
                    bonaAdditionsIds: env.bonaAdditionsIds,
                }),
            },
            {
                provide: FLOORI_ICON_FAMILY,
                useValue: companyConfig?.themeSettings?.iconFamily || FlooriIcons.hero,
            },
            {
                provide: COMPANY_CONFIG_RAW,
                useValue:
                    (companyConfig as CompanyConfig)?.statusCode === 403
                        ? null
                        : configWrapper(companyConfig, setup.clientKey, env.bonaAdditionsIds || ''),
            },
            {
                provide: COMPANY_VENDOR_ID,
                useValue: setup.clientKey,
            },
            {
                provide: FLOORI_CONTEXT_ID,
                useValue: uuidv4(),
            },
            providePrimeNG({
                theme: {
                    preset: Lara,
                    options: {
                        darkModeSelector: false,
                    },
                },
            }),
            TranslationsLoaderService,
            provideAnimations(),
        ],
    }).catch(err => console.error(err));
});
