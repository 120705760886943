import { Component, input } from '@angular/core';

@Component({
    selector: 'floori-form-field',
    standalone: true,
    imports: [],
    templateUrl: './floori-form-field.component.html',
    styleUrl: './floori-form-field.component.scss',
})
export class FlooriFormFieldComponent {
    label = input.required<string>();
    required = input<boolean>(false);
    errors = input<string[]>([]);
    showLabel = input<boolean>(false);
}
