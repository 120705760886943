<nav class="floori-paginator">
    <div class="items-per-page">
        <floori-select
            [options]="itemsPerPageOptions"
            [ngModel]="itemsPerPage()"
            (ngModelChange)="onItemsPerPageChange($event)">
        </floori-select>
        <span>{{ "paginator.resultsPerPage" | flTranslate }}</span>
    </div>

    <div class="navigation">
        <button
            floori-icon-button
            class="secondary big"
            [disabled]="isFirstPage()"
            (click)="onFirst()">
            <floori-icon name="fl_skip_left_o"></floori-icon>
        </button>

        <button
            floori-icon-button
            class="secondary big"
            [disabled]="isFirstPage()"
            (click)="onPrevious()">
            <floori-icon name="fl_chevron_left_s"></floori-icon>
        </button>

        <span class="page-info">
            {{ currentPage() }}
            {{ "paginator.of" | flTranslate }}
            {{ totalPages() }}
        </span>

        <button
            floori-icon-button
            class="secondary big"
            [disabled]="isLastPage()"
            (click)="onNext()">
            <floori-icon name="fl_chevron_right_s"></floori-icon>
        </button>

        <button
            floori-icon-button
            class="secondary big"
            [disabled]="isLastPage()"
            (click)="onLast()">
            <floori-icon name="fl_skip_right_o"></floori-icon>
        </button>

        <span class="items-info"
            >{{ startRange() }}–{{ endRange() }} {{ "paginator.of" | flTranslate }}
            {{ totalItems() }}</span
        >
    </div>
</nav>
