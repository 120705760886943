import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlooriIconComponent } from '../floori-icon';
import { FlooriButtonComponent } from '../floori-button';
import { FlooriSelectComponent } from '../floori-select';
import { FormsModule } from '@angular/forms';
import { TranslationsModule } from '../../translations';
import { ItemsPerPage } from './items-per-page.type';

@Component({
    selector: 'floori-paginator',
    standalone: true,
    imports: [
        CommonModule,
        FlooriIconComponent,
        FlooriButtonComponent,
        FlooriSelectComponent,
        FormsModule,
        TranslationsModule,
    ],
    templateUrl: './floori-paginator.component.html',
    styleUrl: './floori-paginator.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlooriPaginatorComponent {
    readonly currentPage = input.required<number>();
    readonly itemsPerPage = input.required<ItemsPerPage>();
    readonly totalItems = input.required<number>();

    readonly pageChange = output<number>();
    readonly itemsPerPageChange = output<ItemsPerPage>();

    readonly itemsPerPageOptions = [
        { value: 10, label: '10' },
        { value: 15, label: '15' },
        { value: 20, label: '20' },
    ];

    isFirstPage = computed(() => this.currentPage() <= 1);
    isLastPage = computed(() => this.currentPage() >= this.totalPages());
    totalPages = computed(() => Math.ceil(this.totalItems() / this.itemsPerPage()));
    startRange = computed(() => (this.currentPage() - 1) * this.itemsPerPage() + 1);
    endRange = computed(() =>
        Math.min(this.currentPage() * this.itemsPerPage(), this.totalItems()),
    );

    onFirst(): void {
        if (this.isFirstPage()) return;
        this.pageChange.emit(1);
    }

    onLast(): void {
        if (this.isLastPage()) return;
        this.pageChange.emit(this.totalPages());
    }

    onNext(): void {
        if (this.isLastPage()) return;
        this.pageChange.emit(this.currentPage() + 1);
    }

    onPrevious(): void {
        if (this.isFirstPage()) return;
        this.pageChange.emit(this.currentPage() - 1);
    }

    onItemsPerPageChange(value: ItemsPerPage): void {
        this.itemsPerPageChange.emit(value);
    }
}
