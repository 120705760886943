import { Component, HostBinding, inject, Renderer2, signal } from '@angular/core';
import { ResponseTypes, SnackbarDialogData, SnackbarPosition } from '../../models';
import { FLOORI_DIALOG_DATA, FlooriDialogModule } from '../floori-dialog';
import { CommonModule } from '@angular/common';
import { TranslationsModule } from '../../translations';
import { FlooriIconComponent } from '../floori-icon';
import { FlooriButtonComponent } from '../floori-button';

@Component({
    selector: 'floori-snackbar',
    standalone: true,
    imports: [
        CommonModule,
        TranslationsModule,
        FlooriIconComponent,
        FlooriDialogModule,
        FlooriButtonComponent,
    ],
    templateUrl: './floori-snackbar.component.html',
    styleUrl: './floori-snackbar.component.scss',
})
export class FlooriSnackbarComponent {
    private readonly data = inject<SnackbarDialogData>(FLOORI_DIALOG_DATA);

    readonly iconName = signal<string>('fl_information_circle_o');

    constructor(private renderer: Renderer2) {
        this.setSnackbarType();
        this.removeScrollblockClass();
    }

    get label(): string {
        return this.data.text;
    }

    get showCloseButton(): boolean {
        return !!this.data.showCloseButton;
    }

    @HostBinding('class.floori-snackbar') hostClass = true;

    @HostBinding('class.top')
    get isTopPosition(): boolean {
        return this.data.position === SnackbarPosition.top;
    }

    @HostBinding('class.bottom')
    get isBottomPosition(): boolean {
        return this.data.position === SnackbarPosition.bottom;
    }

    @HostBinding('class.top-left')
    get isTopLeftPosition(): boolean {
        return this.data.position === SnackbarPosition.topLeft;
    }

    @HostBinding('class.top-right')
    get isTopRightPosition(): boolean {
        return this.data.position === SnackbarPosition.topRight;
    }

    @HostBinding('class.bottom-left')
    get isBottomLeftPosition(): boolean {
        return this.data.position === SnackbarPosition.bottomLeft;
    }

    @HostBinding('class.bottom-right')
    get isBottomRightPosition(): boolean {
        return this.data.position === SnackbarPosition.bottomRight;
    }

    @HostBinding('class.error')
    get isErrorType(): boolean {
        return this.data.type === ResponseTypes.error;
    }

    @HostBinding('class.success')
    get isSuccessType(): boolean {
        return this.data.type === ResponseTypes.success;
    }

    @HostBinding('class.info')
    get isInfoType(): boolean {
        return this.data.type === ResponseTypes.info;
    }

    private setSnackbarType(): void {
        switch (this.data.type) {
            case ResponseTypes.error:
                this.iconName.set('fl_exclamation_circle_o');
                break;
            case ResponseTypes.success:
                this.iconName.set('fl_check_circle_o');
                break;
            case ResponseTypes.info:
                this.iconName.set('fl_information_circle_o');
                break;
            default:
                this.iconName.set('fl_information_circle_o');
        }
    }

    private removeScrollblockClass() {
        const htmlElement = document.documentElement;
        if (htmlElement.classList.contains('cdk-global-scrollblock')) {
            this.renderer.removeClass(htmlElement, 'cdk-global-scrollblock');
        }
    }
}
