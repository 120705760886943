import { ChangeDetectionStrategy, Component, output, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FlooriControl } from '../../utils/floori-control';

@Component({
    selector: 'floori-toggle-switch',
    standalone: true,
    imports: [],
    templateUrl: './floori-toggle-switch.component.html',
    styleUrl: './floori-toggle-switch.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: FlooriToggleSwitchComponent,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlooriToggleSwitchComponent extends FlooriControl implements ControlValueAccessor {
    readonly checkedChange = output<boolean>();

    isChecked = signal(false);

    onToggle(event: Event): void {
        const checked = (event.target as HTMLInputElement).checked;
        this.isChecked.set(checked);
        this.onChange(checked);
        this.onTouch(checked);
        this.checkedChange.emit(checked);
        this.cdr.markForCheck();
    }

    writeValue(value: boolean): void {
        if (this.onChangeInit) {
            this.isChecked.set(value);
        }
    }
}
