@if (showLabel()) {
<label>
    <span>{{ label() }}</span>
    @if (required()) {
    <span class="asterisk">*</span>
    }
</label>
}

<!-- floori-field component -->
<ng-content></ng-content>

<div class="errors">
    @for (error of errors(); track $index) {
    <p class="error">{{ error }}</p>
    }
</div>
