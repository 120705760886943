<floori-card class="modal-content">
    <h2>{{ header() }}</h2>
    <ng-content></ng-content>

    <div class="buttons" [ngClass]="{ 'two-buttons': secondaryButtonName() }">
        @if (secondaryButtonName()) {
        <button floori-button class="outline" (click)="onSecondaryClicked()">
            {{ secondaryButtonName() }}
        </button>
        } @if (primaryButtonName()) {
        <button floori-button class="primary" (click)="onPrimaryClicked()">
            {{ primaryButtonName() }}
        </button>
        }
    </div>
</floori-card>
