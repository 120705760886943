<div
    class="select-input"
    (click)="toggleDropdown()"
    [class.disabled]="disabled"
    [class.focused]="focused">
    <span class="selected-value">
        {{ selectedLabel || placeholder() }}
    </span>
    <floori-icon name="fl_chevron_down_o"></floori-icon>
</div>

@if (isOpened()) {
<div class="select-dropdown" [class]="dropdownPosition()">
    @for (option of options(); track option.value) {
    <div
        class="select-item"
        [class.selected]="option.value === value"
        (click)="selectOption(option)">
        <span class="select-item-content">{{ option.label }}</span>
    </div>
    }
</div>
}
